<template>
  <el-dialog :visible.sync="visible" class="see-grid__detail">
    <el-descriptions border title="网格详情">
      <el-descriptions-item label="部门">{{
        dataInfo.departmentName
      }}</el-descriptions-item>
      <el-descriptions-item label="部门负责人">{{
        dataInfo.depatmentChargeName
      }}</el-descriptions-item>
      <el-descriptions-item label="班组">{{
        dataInfo.teamName
      }}</el-descriptions-item>
      <el-descriptions-item label="班组负责人">{{
        dataInfo.teamChargeName
      }}</el-descriptions-item>
      <el-descriptions-item label="岗位负责人">{{
        dataInfo.positionChargeName
      }}</el-descriptions-item>
      <el-descriptions-item label="职位">{{
        dataInfo.zhize
      }}</el-descriptions-item>
      <el-descriptions-item label="业务">{{
        dataInfo.yewu
      }}</el-descriptions-item>

      <el-descriptions-item label="区域">{{
        dataInfo.quyu
      }}</el-descriptions-item>
      <el-descriptions-item label="设备">{{
        dataInfo.shebei
      }}</el-descriptions-item>
      <el-descriptions-item label="生效时间">{{
        dataInfo.effectiveDate ? dataInfo.effectiveDate.split(" ")[0] : ""
      }}</el-descriptions-item>
    </el-descriptions>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dataInfo: "",
    };
  },
  methods: {
    init(id) {
      this.getDataInfo(id);
      this.grid_id = id || "";
      this.visible = true;
    },
    // 获取部门网格详情
    getDataInfo(id) {
      this.$http.gridposition.info({ id }).then((res) => {
        if (res.data) {
          this.dataInfo = res.data;
        }
      });
    },
  },
};
</script>